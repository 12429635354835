import './footer.css'
import gmail from "../assets/gmail.png"
import instagram from "../assets/instagram.png"
import facebook from "../assets/facebook.png"
import github from "../assets/github.png"


function Footer(){
    return (
        <div>

            <svg className="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
                <path className="footer-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
            </svg>

            <div className="footer">
                <div className='about'> 
                <div className='footer-logo'>
                        <a href='/'>WeHustleCode</a>
                </div>
                <div className='intro'>
                        <p>WeHustleCode is a team of Software Engineering and Computer Science experts dedicated to helping students around the world achieve their goals.</p>
                </div>
                </div>

                <div className='contact'>
                    <div>
                        <h2>Connect with us Now</h2>
                    </div>
                    <div className='links-container'>
                        <ul>
                            <li><a href="#"> <img src={gmail} alt="Gmail" /> </a></li>
                            <li><a href="#"> <img src={instagram} alt="Instagram" /> </a></li>
                            <li><a href="#"> <img src={facebook} alt="Facebook" /> </a></li>
                            <li><a href="#"> <img src={github} alt="Github" /> </a></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='footer-end'>
                <p>© 2024 WeHustleCode. All rights reserved.</p>
            </div>
        </div>
    )
}

export default Footer
