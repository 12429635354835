import bg1 from '../assets/bg-4.png';

function HeroSection(){
    return (
        <div className="hero-section">
            <h1>Your Gateway to Success in Computer Science Starts Here</h1>
            
            <div className='intro'>

                <div className='intro-text'>

                    <p>Welcome to our online education platform, where your gateway to success in computer science begins! 
                        Whether you're a beginner looking to ignite your programming passion or an experienced learner 
                        aiming to enhance your skills, we have everything you need to excel in the world of technology. </p>
                </div>

                <div className='intro-img'>
                    <img src={bg1} alt="Person Doing Code"></img>
                </div>
                    
            </div>
        </div>
    );
}

export default HeroSection;