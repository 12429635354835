import './HomePagee.css'
import React, { useState , useEffect , useCallback} from 'react';

import IntroSection from '../components/carousel/IntroSection';
import FieldsSection from '../components/carousel/FieldsSection';
import ContactSection from '../components/carousel/ContactSection';



function HomePagee() {

    const [selectedoption , setselectedoption] = useState([]) ; 
    const [selectedField , setselectedField] = useState([]) ; 
    const [requirements , setrequirements] = useState("") ;

    useEffect(() => {
        const handleKeyDown = (e) => {
          if (e.keyCode === 9) {
            e.preventDefault();
          }
        };
    
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

    const [right , setRight] = useState(0);

    const toleft = () => {
        if(right !== -100){
            setRight(right - 100);
        }
    }

    const toright = useCallback(() => {
        if (right < 300) {
            setRight(right + 100);
        }
        console.log("right" , right);
    }, [right]);


    return(
        <div className="carousel">
            <div className='carousel-container'  style={{right:`${right}%`}}>
                
                <IntroSection 
                    selectedoption={selectedoption}
                    setselectedoption={setselectedoption}
                    toright={toright}
                />
                
                <FieldsSection
                    selectedField={selectedField}
                    setselectedField={setselectedField}
                    toleft={toleft}
                    toright={toright}
                />

                <ContactSection
                    requirements={requirements}
                    setrequirements={setrequirements}
                    selectedoption={selectedoption}
                    selectedField={selectedField}
                    toleft={toleft}
                />    

            
            </div>

        </div>
    )

}


export default HomePagee;