const fields = ["Java" , "C++" , "Software Engineering" , "React" , "Unity" , "Python" , 
                "Data Science" ,
                 "Machine Learning" , "Artificial Intelligence" , "Web Development" , 
                 "Android Development" , "iOS Development",
                "Game Development" , "Database" , "Computer Graphics" , "Computer Vision" , 
                "Natural Language Processing" , "Operating System" ,
                "Computer Networks" , "Computer Architecture" , "Computer Organization" , 
                "Data Structures" , "Algorithms" , "Discrete Mathematics"  , "Other"
                ]

const fieldColors = ["#f9b234" , "#3ecd5e" , "#e44002" , "#952aff" , "#cd3e94" , "#cd3e94" , "#00a6b0" , 
                    "#009651" ,"#96008c" , "#d50060" , "#ff8261" , "#3bab9d"]

const whatsappContact = "+923220967596"   ;
const emailAddress = "team@wehustlecode.com" ; 

export { fields , fieldColors , whatsappContact , emailAddress } ;
