import './About.css'
import gmail from "../assets/gmail.png"
import whatsapp from "../assets/whatsapp.png"
import { whatsappContact , emailAddress } from "../data/data"
import about from '../assets/about.png';

function About(){

    const whatsappUrl = `https://web.whatsapp.com/send?phone=${encodeURIComponent(whatsappContact)}&text=${encodeURIComponent("")}`

    const whatsappUrlApp = `https://api.whatsapp.com/send?phone=${encodeURIComponent(whatsappContact)}&text=${encodeURIComponent("")}`

    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(emailAddress)}&su=${encodeURIComponent("")}&body=${encodeURIComponent("")}`
    

    return(
        <div className='about-us'>
          <div className='about-us-main'>
            <div className='about-us-content'>
                <h1>Who Are We?</h1>
                <p> <span>WeHustleCode</span> is a team of software and computer science experts dedicated to helping students achieve their goals in the tech world. With our passion for coding and commitment to excellence, we offer exceptional guidance and support for your journey to success. <br/> Join us and take the next step in your learning and growth!</p> 
            </div>
            <div className = 'about-img' alt= "about-img">
                    <img src={about} alt="About Programmers"></img>
                </div> 
            </div>

            <div className='Contact-us-content'>
                <h1>Contact Us</h1>
                <div className='social-icons-container'>
                               
                    <div>
                        <a href={whatsappUrlApp} target="_blank" rel="noopener noreferrer">
                            <img src={whatsapp} alt="WhatsApp" />
                        </a>
                        <p>App</p>
                    </div>

                    <div>
                        <a href={gmailUrl} target="_blank" rel="noopener noreferrer">
                            <img src={gmail} alt="Gmail" />
                        </a>
                        <p>Gmail</p>
                    </div>

                    <div>
                        <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
                            <img src={whatsapp} alt="WhatsApp" />
                        </a>
                        <p>Web</p>
                    </div>
                                        
                </div>

            </div>

        </div>
    );
}

export default About;
