import './navbar.css';
import { useRef } from 'react';
import menu from "../assets/menu.svg";
import logo from "../assets/logo1.png"
import { Link } from 'react-router-dom';


function Navbar(){
   
    const navLinks = useRef(null);
    let bool = false;

    function toggle(){
        if (bool) {
            navLinks.current.style.maxHeight = '0px';    
            bool = false;
        }else{
            navLinks.current.style.maxHeight = '200px';
            bool = true;
        }
    }

    return(
        <div>
            <div className='navbar'>
                <div className='logo'>
                    <Link to='/'>
                        <img src={logo} alt="logo" id='logoimg'/>
                    </Link>
                    <img className="hamburger" src={menu} alt="menu" onClick={toggle}/>
                </div>
                <div className='nav-links' ref={navLinks}>
                    <Link to='/'>Home</Link>
                    <Link to='/OurWork'>Previous Work</Link>
                    <Link to='/AboutUs'>About Us</Link>
                </div>
            </div>
        </div>
    );
}

export default Navbar ; 