import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom' 
import HomePagee from './pages/HomePagee'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import About from './pages/About'
import PreviousWork from './pages/PreviousWork';


function App() {
  return (
    <BrowserRouter>
        <Navbar></Navbar>
          <Routes>
            <Route path='/' element={<HomePagee></HomePagee>}/>
            <Route path='/AboutUs' element={<About></About>}/>
            <Route path='/OurWork' element={<PreviousWork></PreviousWork>}/>
            <Route path='*' element={<p>No Page Found</p>} ></Route>
          </Routes>
        <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
