function generateWhatsAppURL(selectedoption , selectedField , requirements , whatsappContact) {

    let message = `Hi, I am interested in ${selectedoption} related to topics ${selectedField}.`;
    message = message + "\n" + "The other requirements are :" + "\n";
    message = message + requirements;

    // return `https://api.whatsapp.com/send?phone=${encodeURIComponent(whatsappContact)}&text=${encodeURIComponent(message)}`;
    return `https://web.whatsapp.com/send?phone=${encodeURIComponent(whatsappContact)}&text=${encodeURIComponent(message)}`;

}

function generateWhatsAppURLAPP(selectedoption , selectedField , requirements , whatsappContact){
    let message = `Hi, I am interested in ${selectedoption} related to topics ${selectedField}.`;
    message = message + "\n" + "The other requirements are :" + "\n";
    message = message + requirements;

    return `https://api.whatsapp.com/send?phone=${encodeURIComponent(whatsappContact)}&text=${encodeURIComponent(message)}`;
    
}

function generateGmailURL(selectedoption , selectedField , requirements , emailAddress) {
    let subject = `Interested in ${selectedoption} `;
    let body = `Hi,\n\nI am interested in ${selectedoption} related to topics ${selectedField}.\n\n`;
    body += `The other requirements are:\n${requirements}`;

    // const email = `mailto:${encodeURIComponent(emailAddress)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    const email = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(emailAddress)}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    return email;
}

export { generateWhatsAppURL , generateGmailURL , generateWhatsAppURLAPP } ;