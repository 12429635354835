import {fields , fieldColors} from "../../data/data"

//make fieldcolors length equal to fields
const modifiedColors = fields.map((field, index) => {
    const colorIndex = index % fieldColors.length;
    return fieldColors[colorIndex];
});

const getColor = (index) => {
    const color = modifiedColors[index];
    return color ; 
}


function FieldsSection({ selectedField, setselectedField , toleft, toright}) {

    const handleItemClick = (e , field) => {
        e.stopPropagation();
        console.log("called")
        if (selectedField.includes(field)) {
            const updatedFields = selectedField.filter((selected) => selected !== field);
            setselectedField(updatedFields);
        } else {
            setselectedField([...selectedField, field]);
        }
    };

  return (
    <div className="carousel-content">
        <div className='content-2'>
            <div className='content-header'>
                <h2>Please specify domains</h2>
            </div>
            <div className='field-container'>
            {
                fields.map((field , index) => {
                    return(
                        <div className={`field-card ${selectedField.includes(field) && "selectedField"}`}
                            key={index} 
                            onClick={(e) => {
                                console.log("clicked");
                                handleItemClick(e,field);
                            }}
                        >
                            <div className='field-card-body' >   
                                    <div className="field-card-bg"
                                        style={{ backgroundColor: getColor(index) }}
                                    >
                                    </div>
                                    <h3>{field}</h3>
                            </div>
                        </div>
                    )
                })
            }
            </div>

            <div className='field-btn'>
                <button onClick={() => toleft()} > Back</button>
                <button onClick={() => toright()}> Next</button>
            </div>
        </div>
    </div> 
  );
}

export default FieldsSection;