import gmail from "../../assets/gmail.png"
import whatsapp from "../../assets/whatsapp.png"

import {  whatsappContact , emailAddress } from '../../data/data';
import { generateWhatsAppURL , generateGmailURL , generateWhatsAppURLAPP } from '../../functions/generateUrl';

function ContactSection({requirements , setrequirements , selectedoption , selectedField , toleft}) {
    
    function getrequirements(e) {
        setrequirements(e.target.value);
    }
    
    return (
    <div className="carousel-content">
        <div className='content-3'>
            <div className='input-container'>
                <p>Please Explain Your Requirements</p>
                <div>
                    <textarea rows="6" cols="40" value={requirements} 
                                onChange={getrequirements}></textarea>
                </div>
            </div>
            <div className='Social Icons'>
                <p>Click to connect to us right Now</p>
                <div className='social-icons-container'>
                    
                    <div>
                        <a 
                        href={generateWhatsAppURLAPP(selectedoption, selectedField , requirements , whatsappContact)} 
                        target="_blank" rel="noopener noreferrer">
                            <img src={whatsapp} alt="WhatsApp" />
                        </a>
                        <p>App</p>
                    </div>

                    <div>
                        <a href={generateGmailURL(selectedoption, selectedField , requirements , emailAddress)}
                        target="_blank" rel="noopener noreferrer">
                            <img src={gmail} alt="Gmail" />
                        </a>
                        <p>Gmail</p>
                    </div>

                    <div>
                        <a href={generateWhatsAppURL(selectedoption, selectedField , requirements , whatsappContact)}
                        target="_blank" rel="noopener noreferrer">
                            <img src={whatsapp} alt="WhatsApp" />
                        </a>
                        <p>Web</p>
                    </div>
                    
                </div>

            </div>

            <div className='field-btn'>
                <button onClick={() => toleft()} > Back</button>
            </div>

        </div>
    </div> 
  );
}

export default ContactSection;