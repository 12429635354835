const colorValues = [
    "#047264", "#3ecd5e", "#2e92bc", "#1b566f", "#cd3e94", "#cd3e94",
    "#1085ac", "#009651", "#96008c", "#d50060", "013d36", "#3ecd5e"
  ];
  
const projects = [
    {
      projectName: "Chatbot Development",
      technologyUsed: "Natural Language Processing (NLP), Machine Learning",
      description: "Develop an AI-powered chatbot that can understand and respond to user queries, providing helpful information or performing specific tasks.",
      color: colorValues[0]
    },
    {
      projectName: "Ploymorphic Virus",
      technologyUsed: "C++ , Operating System , MultiProcessing , Threading",
      description: "Developed a polymorphic virus that can change its signature every time it replicates itself. It can also infect other files and spread across the system.",
      color: colorValues[1]
    },
    {
      projectName: "Clearance System",
      technologyUsed: "Java , SQL , Databases , GUI , JavaFX , Java Swing",
      description: "Developed a clearance system for a university that can be used by students and faculty to apply for clearance and track the status of their application.",
      color: colorValues[2]
    },
    {
      projectName: "Online Shopping System",
      technologyUsed: "Java , SQL , Databases , GUI , JavaFX , Java Swing",
      description: "Developed an online shopping system that can be used by customers to browse and purchase products, and by administrators to manage the inventory.",
      color: colorValues[3]
    },
    {
      projectName: "Online Banking System",
      technologyUsed: "React , Django , SQL , REST API",
      description: "Developed an online banking system that can be used by customers to manage their accounts and by administrators to manage the bank’s operations.",
      color: colorValues[4]
    },
    {
      projectName: "Online End to End Encrypted Chat System",
      technologyUsed: "React , Django , SQL , REST API , JEncryption",
      description: "Developed an online chat system that can be used by users to communicate with each other securely using end-to-end encryption.",
      color: colorValues[5]
    },
    {
      projectName: "Snake Game",
      technologyUsed: "Java , Java Swing",
      description: "Developed a snake game using Java Swing that can be played by a single player. The game also has a leaderboard to track the highest scores.",
      color: colorValues[6]
    },
    {
      projectName: "Tic Tac Toe Game",
      technologyUsed: "Java , Java Swing",
      description: "Developed a tic tac toe game using Java Swing that can be played by two players. The game also has a leaderboard to track the highest scores.",
      color: colorValues[7]
    },
    {
      projectName: "Online Quiz System",
      technologyUsed: "Java , SQL , Databases , GUI , JavaFX , Java Swing",
      description: "Developed an online quiz system that can be used by students to take quizzes and by instructors to create and manage quizzes.",
      color: colorValues[8]
    },
    {
      projectName: "Task Manager",
      technologyUsed: "Java , SQL , Databases , GUI , JavaFX , Java Swing",
      description: "Developed a task manager that can be used by users to create and manage tasks. The application also has a calendar to track the deadlines.",
      color: colorValues[9]
    },
    {
      projectName: "Image Recognition",
      technologyUsed: "Deep Learning, Convolutional Neural Networks (CNN)",
      description: "Build a system that can accurately identify objects or patterns within images, enabling applications like facial recognition, object detection, or autonomous driving.",
      color: colorValues[1]
    },
    {
      projectName: "Data Visualization Dashboard",
      technologyUsed: "Data visualization libraries (e.g., D3.js, Matplotlib)",
      description: "Create an interactive dashboard that presents complex data in a visually appealing and user-friendly manner, allowing users to explore and analyze information effectively.",
      color: colorValues[2]
    },
    {
      projectName: "Blockchain-based Voting System",
      technologyUsed: "Blockchain, Smart Contracts",
      description: "Develop a secure and transparent voting system using blockchain technology to ensure the integrity of the voting process and prevent tampering or fraud.",
      color: colorValues[3]
    },
    {
      projectName: "Recommender System",
      technologyUsed: "Collaborative Filtering, Machine Learning",
      description: "Build a personalized recommendation engine that suggests items or content based on user preferences and behavior, enhancing user experiences in various domains like e-commerce or entertainment.",
      color: colorValues[4]
    },
    {
      projectName: "Autonomous Drone Navigation",
      technologyUsed: "Computer Vision, Path Planning Algorithms",
      description: "Design an autonomous drone system that can navigate and explore its environment using computer vision techniques and intelligent path planning algorithms.",
      color: colorValues[5]
    },
    {
      projectName: "Fraud Detection in Financial Transactions",
      technologyUsed: "Machine Learning, Anomaly Detection",
      description: "Develop a system that can identify fraudulent activities in financial transactions by analyzing patterns and detecting anomalies in real-time data.",
      color: colorValues[6]
    },
    {
      projectName: "Sentiment Analysis",
      technologyUsed: "Natural Language Processing (NLP), Machine Learning",
      description: "Create a sentiment analysis tool that can analyze text data (e.g., social media posts, customer reviews) to determine the sentiment (positive, negative, or neutral) and understand public opinion.",
      color: colorValues[7]
    },
    {
      projectName: "Internet of Things (IoT) Home Automation",
      technologyUsed: "IoT Protocols, Sensor Networks",
      description: "Build a home automation system using IoT devices and sensor networks to control and monitor various aspects of a home, such as temperature, lighting, and security.",
      color: colorValues[8]
    },
    {
      projectName: "Machine Translation",
      technologyUsed: "Neural Machine Translation, Recurrent Neural Networks (RNN)",
      description: "Develop a machine translation system that can automatically translate text or speech from one language to another, enabling cross-lingual communication.",
      color: colorValues[9]
    },
    {
      projectName: "Real-Time Object Detection",
      technologyUsed: "Deep Learning, Computer Vision",
      description: "Develop a system that can detect and classify objects in real-time using deep learning techniques, enabling applications like video surveillance or autonomous vehicles.",
      color: colorValues[0]
    },
    {
      projectName: "Social Network Analysis",
      technologyUsed: "Graph Theory, Data Mining",
      description: "Perform analysis on social networks to extract insights, identify influential nodes, detect communities, or analyze the spread of information or diseases.",
      color: colorValues[1]
    },
    {
      projectName: "Natural Language Generation",
      technologyUsed: "Natural Language Processing (NLP), Deep Learning",
      description: "Build a system that can generate human-like text based on given prompts or conditions, enabling applications like automated report writing or content generation.",
      color: colorValues[2]
    },
    {
      projectName: "Gesture Recognition",
      technologyUsed: "Computer Vision, Machine Learning",
      description: "Develop a system that can recognize and interpret human gestures captured through cameras, enabling applications like gesture-based user interfaces or sign language translation.",
      color: colorValues[3]
    },
    {
      projectName: "Automated Code Review",
      technologyUsed: "Machine Learning, Static Analysis",
      description: "Create a system that can automatically review and analyze code to detect issues, provide suggestions for improvements, and ensure coding standards and best practices.",
      color: colorValues[4]
    },
    {
      projectName: "Sentiment Analysis for Social Media",
      technologyUsed: "Natural Language Processing (NLP), Text Mining",
      description: "Develop a sentiment analysis tool specifically designed for social media data, enabling businesses or organizations to understand public sentiment on platforms like Twitter or Facebook.",
      color: colorValues[5]
    },
    {
      projectName: "Music Recommendation System",
      technologyUsed: "Machine Learning, Collaborative Filtering",
      description: "Build a personalized music recommendation system that suggests songs or playlists based on user preferences, listening history, or similarities with other users.",
      color: colorValues[6]
    },
    {
      projectName: "Automated Image Captioning",
      technologyUsed: "Deep Learning, Natural Language Processing (NLP)",
      description: "Develop a system that can generate descriptive captions for images automatically, combining computer vision and natural language processing techniques.",
      color: colorValues[7]
    },
    {
      projectName: "Virtual Reality (VR) Simulation",
      technologyUsed: "Virtual Reality (VR), 3D Modeling",
      description: "Create an immersive virtual reality simulation that can provide realistic experiences and interactions in various domains like education, training, or entertainment.",
      color: colorValues[8]
    },
    {
      projectName: "Cybersecurity Incident Response",
      technologyUsed: "Network Security, Intrusion Detection",
      description: "Develop an incident response system that can detect and respond to cybersecurity threats, mitigate damages, and restore normal operations in the event of an attack.",
      color: colorValues[9]
    },
    {
      projectName: "Autonomous Robotic Arm",
      technologyUsed: "Robotics, Computer Vision, Path Planning",
      description: "Design and build an autonomous robotic arm that can manipulate objects, perform tasks, and adapt to different environments using computer vision and planning algorithms.",
      color: colorValues[0]
    },
    {
      projectName: "Emotion Recognition from Facial Expressions",
      technologyUsed: "Computer Vision, Deep Learning",
      description: "Develop a system that can recognize and interpret human emotions based on facial expressions, enabling applications like affective computing or human-computer interaction.",
      color: colorValues[1]
    },
    {
      projectName: "Intelligent Tutoring System",
      technologyUsed: "Machine Learning, Natural Language Processing (NLP)",
      description: "Build an intelligent tutoring system that can provide personalized instruction, adapt to the learner's needs, and assess learning progress in various subjects.",
      color: colorValues[2]
    },
    {
      projectName: "Automated Speech Recognition",
      technologyUsed: "Speech Processing, Machine Learning",
      description: "Develop a system that can convert spoken language into written text, enabling applications like voice assistants, transcription services, or voice-controlled systems.",
      color: colorValues[3]
    },
    {
      projectName: "Smart Traffic Management System",
      technologyUsed: "Internet of Things (IoT), Data Analytics",
      description: "Create a smart traffic management system that uses IoT sensors, real-time data, and analytics to optimize traffic flow, reduce congestion, and improve transportation efficiency.",
      color: colorValues[4]
    },
    {
      projectName: "Fault Diagnosis in Industrial Systems",
      technologyUsed: "Machine Learning, Data Analysis",
      description: "Develop a system that can detect and diagnose faults or anomalies in industrial systems using sensor data, enabling proactive maintenance and minimizing downtime.",
      color: colorValues[5]
    },
    {
      projectName: "Computer-Aided Drug Discovery",
      technologyUsed: "Machine Learning, Bioinformatics",
      description: "Utilize computational techniques and machine learning algorithms to aid in the discovery and design of new drugs, accelerating the drug development process.",
      color: colorValues[6]
    },
    {
      projectName: "Smart Home Energy Management",
      technologyUsed: "Internet of Things (IoT), Energy Optimization",
      description: "Build a system that optimizes energy consumption in smart homes, using IoT devices, data analytics, and machine learning to reduce energy waste and promote sustainability.",
      color: colorValues[7]
    },
    {
      projectName: "Augmented Reality (AR) Gaming",
      technologyUsed: "Augmented Reality (AR), Mobile Development",
      description: "Create immersive gaming experiences by combining virtual elements with the real world using augmented reality technology on mobile platforms.",
      color: colorValues[8]
    },
    {
      projectName: "Data Privacy and Anonymization",
      technologyUsed: "Privacy-preserving Techniques, Data Security",
      description: "Develop privacy-preserving methods and techniques to ensure the protection of sensitive data and the anonymity of individuals in data-driven applications.",
      color: colorValues[9]
    },
    {
      projectName: "Clearance System",
      technologyUsed: "Java , SQL , Databases , GUI , JavaFX , Java Swing",
      description: "Developed a clearance system for a university that can be used by students and faculty to apply for clearance and track the status of their application.",
      color: colorValues[2]
    }
  ];

export  { projects };