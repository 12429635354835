import HeroSection from "../HeroSec";

function IntroSection({selectedoption, setselectedoption , toright}) {

    const handleOptionSelection = async (option) => {

        if (selectedoption.includes(option)) {
            console.log(option, "removed");
            setselectedoption(prevOptions => {
                const updatedOptions = prevOptions.filter((selected) => selected !== option);
                if (updatedOptions.length > 0) {
                    toright(); 
                }
                return updatedOptions;
            });
        } else {
            console.log(option, "added");
            setselectedoption(prevOptions => {
                const updatedOptions = [...prevOptions, option];
                if (updatedOptions.length > 0) {
                    toright(); 
                }
                return updatedOptions;
            });
        }

    };
    
    
  return (
    <div className="carousel-content">
        <div className='content-1'>

            <HeroSection />

            <div className='options-container'>
                <h2>What Do you need help with</h2>

                    <div className='card-container'>
                        
                        <div className= {`option-card card1 ${selectedoption.includes("Assignments") && "selectedoption"}`} 
                                          onClick={()=> handleOptionSelection("Assignments")}>
                            <h3>Assignments</h3>
                        </div>

                        <div className={`option-card card2 ${selectedoption.includes("Projects") && "selectedoption"}`}
                                         onClick={()=> handleOptionSelection("Projects")}>
                            <h3>Projects</h3>
                        </div>

                        <div className={`option-card card3 ${selectedoption.includes("Tutoring") && "selectedoption"}`}
                                         onClick={()=> handleOptionSelection("Tutoring")}>
                            <h3>Tutoring</h3>
                        </div>

                    </div>
                
                {/* <div className='options-btn'>
                    <button onClick={() => toright()}>Next</button>
                </div> */}
            </div>
        </div>
    </div> 
  )
}

export default IntroSection;