import "./PreviousWork.css";

import { projects } from "../data/previousworkData"


function PreviousWork() {

  return (

    <div>
      <div className="gallery"> 
          <div className="gallery-container">
            {projects.map((project, index) => (
              <div className="gallery-card" key={index}>
                <h3 className="text">{project.projectName}</h3>
                <p className="text">{project.technologyUsed}</p>
                <div className="gallery-card-overlay" style={{backgroundColor:`${project.color}9e`}}>
                  <p>{project.description}</p>
                </div>
              </div>
            ))}
          </div>
      </div>
    </div>
  );
}

export default PreviousWork;
